import { all, call, put, takeEvery } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import apiClient from "@api/client"
import { UserEmailReceiverOption } from "@api/schema"
import { INewUserPasswordAction, ISendEmailToUsersAction, UserManagementActionTypes } from "@redux/actions/userManagement"
import {
  newSingleEntityUsecaseRequestRunningAction,
  newSingleEntityUsecaseRequestSuccessAction,
  usecaseRequestRunningAction, usecaseRequestSuccessAction
} from "@redux/helper/actions"
import { showErrorsInTestEnvironment } from "@redux/helper/sagas"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { EntityType, ScopeTypes } from "@redux/reduxTypes"
import { Routes } from "@services/routes"
import { SubmissionError } from "@services/submissionError"
import { BASE_URL } from "config"

export function* usersWatcherSaga(): any {
  yield all([
    takeEvery(UserManagementActionTypes.NewUserPassword, withCallback(newUserPasswordSaga)),
    takeEvery(UserManagementActionTypes.SendEmailToUsers, sendEmailToUsersSaga)
  ])
}


/**
 * saga to set a new password by a user
 */
function* newUserPasswordSaga(action: INewUserPasswordAction) {
  try {
    // the API uses {{param}} as placeholder while Next uses [param]:
    const route = Routes.ConfirmPasswordReset.replace(/\[/g, "{{").replace(/\]/g, "}}")
    const validationUrl = BASE_URL + route

    yield put(usecaseRequestRunningAction(ScopeTypes.UserOperation))
    // validation-URL is given to the API to include into the mail
    const res: boolean = yield call(apiClient.newPassword, action.user, { validationUrl })
    yield put(usecaseRequestSuccessAction(ScopeTypes.UserOperation, action.user))
    yield call(action.actions.onSuccess, res)

    return res
  } catch (err) {
    if (err instanceof SubmissionError) {
      yield call(action.actions.setErrors, err.errors)
    } else if (err instanceof Error) {
      yield call(action.actions.setErrors, { error: err.message })
    }

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    showErrorsInTestEnvironment("newUserPasswordSaga", errorMessage, action, err)
    yield put(usecaseRequestRunningAction(ScopeTypes.UserOperation, errorMessage))
    yield call(action.actions.setSubmitting, false)

    return null
  }
}
/**
 * saga to send an email to all or a selection of users by a process manager, who will get a confirmation email
 *
 */
function* sendEmailToUsersSaga(action: ISendEmailToUsersAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}
  const usecaseKey = action.type // @TODO fixme: usecaseKey may be refactored into action, see loadModelAction etc
  try {

    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.User, usecaseKey))
    if (action.userEmail.receiverOption === UserEmailReceiverOption.All) {
      action.userEmail.emailReceivers = []
    }
    yield call(apiClient.sendEmailToUsers, action.currentProcess.id, action.userEmail)
    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.User, usecaseKey, null))
    // tell formik, that message was submitted
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }
    // call onSuccess, if defined
    if (onSuccess) {
      yield call(action.actions.onSuccess)
    }
    return true
  } catch (err) {
    if (err instanceof Error) {
      if (err instanceof SubmissionError) {
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: err.message })
      }
    }

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    showErrorsInTestEnvironment("sendEmailToUsersSaga", errorMessage, action, err)
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Project, usecaseKey, errorMessage))
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return false
  }
}
