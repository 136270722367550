import { Action } from "redux"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { putWait, withCallback } from "redux-saga-callback"

import apiClient from "@api/client"
import { IModel, IProject, IProjectCreation, IProjectFollowership, IProjectMembership, IUser, MembershipRole, ProjectProgress } from "@api/schema"
import { IProjectFollowershipWriteDTO, IUserWriteDTO, transformEntityToWriteDTO } from "@api/schema-dto"
import { IAddNotificationAction, addNotificationAction } from "@redux/actions/notifications"
import { IProcessOnboardingDataAfterRegistrationAction, IProcessOnboardingDataBeforeRegistrationAction, RegistrationUsecases } from "@redux/actions/registration"
import { createModelSuccessAction, newLoadCollectionAction, newSingleEntityUsecaseRequestRunningAction, newSingleEntityUsecaseRequestSuccessAction } from "@redux/helper/actions"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { AppState } from "@redux/reducer"
import { NotificationWithButtonLinkConfig } from "@redux/reducer/notifications"
import { EntityType, ScopeTypes } from "@redux/reduxTypes"
import { getCurrentUser } from "@redux/saga/currentUser"
import { prefixedKey } from "@services/i18n"
import { Routes, routeWithParams } from "@services/routes"
import { SubmissionError } from "@services/submissionError"
import { iriFromIModelOrIRI } from "@services/util"

import { IProcessOnboardingDataAfterLoginAction, UserAccountUsecases, loadCurrentUserAction } from "./userAccount"



/** *************************************************************************************************
 * This enum defines the usecases around the "onboarding".
 * "Onboarding" is everything related to elements that the user creates before login/registration,
 * to persist those elements after a successful login/registration.
 */

export enum OnboardingUsecases {
  /**
   * add "new idea" data to the onboarding state
   */
  AddNewIdea = "_usecase_add_onboarding_new_idea",
  /**
   * add "new project from idea" data to the onboarding state
   */
  AddNewProjectFromIdea = "_usecase_add_onboarding_new_project_from_idea",
  /**
   * add "new project member application" data to the onboarding state
   */
  AddNewProjectMemberApplication = "_usecase_add_onboarding_new_project_member_application",
  /**
   * add "new project followership" data to the onboarding state
   */
  AddNewProjectFollowership = "_usecase_add_onboarding_new_project_followership",

  /**
   * reset the state after the onboarding data has been processed
   */
  ResetOnboardingData = "_usecase_reset_onboarding_data",
}


// *************************************************************************************************
// #region onboarding specific definitions

export function* onboardingWatcherSaga(): any {
  yield all([
    takeLatest(RegistrationUsecases.ProcessOnboardingDataBeforeRegistration, withCallback(preRegistrationForOnboardingSaga)),
    takeLatest(RegistrationUsecases.ProcessOnboardingDataAfterRegistration, postRegistrationForOnboardingSaga),
    takeLatest(UserAccountUsecases.ProcessOnboardingDataAfterLogin, postLoginForOnboardingSaga),
  ])
}

/**
 * types of existing onboarding elements
 */
export enum OnboardingType {
  None = "none",

  // NOTE: those strings are used within the translation files, so do not change them without changing there too
  NewIdea = "newIdea",
  NewProjectFromIdea = "newProjectFromIdea",
  NewProjectMemberApplication = "newProjectMemberApplication",
  NewProjectFollowership = "newProjectFollowership",
}

// #endregion


// *************************************************************************************************
// #region pre registration handling

/**
 * Before a user registration request is sent to the API, check if they previously created a any object of OnboardingType,
 * if yes attach this information to the user object that is passed to the API.
 *
 * @param action IUserRegistrationUpcomingAction
 */
function* preRegistrationForOnboardingSaga(action: IProcessOnboardingDataBeforeRegistrationAction): Generator<any, IUserWriteDTO, any> {

  // NOTE this saga has no API requests, therefore no try/catch (TODO: check if that's a valid pattern/reasoning)
  // It does not need any API requests, since it's just logic building the onboarding data.
  // As such, it IS asynch and it DOES need access to state data (via asynch `yield select`), so it NEEDs to be a Saga (afaik).

  const onboardingType: OnboardingType = yield select(selectOnboardingType)
  if (onboardingType === OnboardingType.None) {
    return action.userWriteDTO
  }

  const userWriteDTO: IUserWriteDTO = {
    ...action.userWriteDTO,
    createdProjects: [],
    projectMemberships: [],
    followership: undefined,
  }

  switch (onboardingType) {

    case OnboardingType.NewIdea:
      const idea: IProject = yield select((s: AppState) => s.onboardingData.newIdea)
      userWriteDTO.createdProjects.push(idea)
      break

    case OnboardingType.NewProjectFromIdea:
      const project: IProjectCreation = yield select((s: AppState) => s.onboardingData.newProjectFromIdea)
      userWriteDTO.createdProjects.push(project)
      break

    case OnboardingType.NewProjectMemberApplication:
      const memberApplication: IProjectMembership = yield select((s: AppState) => s.onboardingData.newProjectMemberApplication)
      userWriteDTO.projectMemberships.push(memberApplication)
      break

    case OnboardingType.NewProjectFollowership:
      const followership: IProjectFollowership = yield select((s: AppState) => s.onboardingData.newProjectFollowership)
      userWriteDTO.followership = transformEntityToWriteDTO(EntityType.ProjectFollowership, followership)
      break
  }

  return userWriteDTO
}

// #endregion

// *************************************************************************************************
// #region post registration handling

/**
 * After a user registered successfully, check if they previously created any object of OnboardingType,
 * if yes add notifications and reset the state.
 *
 * @param action IUserRegistrationCompletedAction
 */
function* postRegistrationForOnboardingSaga(action: IProcessOnboardingDataAfterRegistrationAction): Generator<any, void, any> {

  // NOTE this saga has no API requests, therefore no try/catch (TODO: check if that's a valid pattern/reasoning)

  if (action.user.createdProjects) {
    for (const project of action.user.createdProjects) {

      // for idea
      if (project.progress === ProjectProgress.Idea) {
        yield put(addNotificationAction("message.project.ideaSaved", "success"))
      }

      // for project
      if (project.progress === ProjectProgress.CreatingProfile) {
        yield put(addNotificationAction("message.project.newProjectSaved", "success"))
        yield put(createModelSuccessAction(EntityType.Project, project))
      }
    }
  }

  // for memberApplication
  if (action.user.projectMemberships?.some((m) => m.role === MembershipRole.Applicant)) {
    yield put(addNotificationAction("message.project.memberships.saved", "success"))
  }

  // for followership
  if (action.user.followerships?.length > 0) {
    yield put(addNotificationAction(prefixedKey("follow-project", "saved"), "success"))
  }

  yield put(resetOnboardingStateAction())
}

// #endregion

// *************************************************************************************************
// #region post login handling

const getEntityTypeFromOnboardingType = (onboardingType: OnboardingType): EntityType => {

  switch (onboardingType) {

    case OnboardingType.NewIdea:
    case OnboardingType.NewProjectFromIdea:
      return EntityType.Project

    case OnboardingType.NewProjectMemberApplication:
      return EntityType.ProjectMembership

    case OnboardingType.NewProjectFollowership:
      return EntityType.ProjectFollowership

    case OnboardingType.None:
      return null
  }

}

/**
 * After a user logged in successfully, check if they previously created any object of OnboardingType,
 * if yes send the data to the API, add notifications and reset the state.
 *
 * @param action IUserLoginCompletedAction
 */
function* postLoginForOnboardingSaga(action: IProcessOnboardingDataAfterLoginAction): Generator<any, IModel, any> {

  const usecaseKey = action.type // @TODO fixme: usecaseKey may be refactored into action, see loadModelAction etc

  const onboardingType: OnboardingType = yield select(selectOnboardingType)
  if (onboardingType === OnboardingType.None) {
    return
  }

  const entityType = getEntityTypeFromOnboardingType(onboardingType)

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(entityType, usecaseKey))

    let resultingObject: IModel = null
    let successfullyCreatedNewObject: boolean = null
    let notificationAction: IAddNotificationAction = null

    switch (onboardingType) {
      case OnboardingType.NewIdea:
        const onboardingIdea: IProject = yield select((s: AppState) => s.onboardingData.newIdea)

        resultingObject = yield call(apiClient.createProject, onboardingIdea)
        successfullyCreatedNewObject = true

        notificationAction =
          addNotificationAction(
            {
              messageKey: "message.project.ideaSaved",
              linkTitleKey: "goto.newProject",
              route: routeWithParams(Routes.CreateProject, { id: (resultingObject as IProject).id })
            } as NotificationWithButtonLinkConfig,
            "success",
            { autoClose: false }
          )

        break

      case OnboardingType.NewProjectFromIdea:
        const onboardingProject: IProjectCreation = yield select((s: AppState) => s.onboardingData.newProjectFromIdea)

        resultingObject = yield call(apiClient.createProject, onboardingProject)
        notificationAction = addNotificationAction("message.project.newProjectSaved", "success")
        successfullyCreatedNewObject = true
        break

      case OnboardingType.NewProjectMemberApplication:
        const onboardingMemberApplication: IProjectMembership = yield select((s: AppState) => s.onboardingData.newProjectMemberApplication)

        // since there may already exist a projectMembership between the user and the project, we must check this first
        const userForMemberApplication: IUser = yield call(getCurrentUser)
        const oldProjectMembershipOftheUser = userForMemberApplication.projectMemberships?.find((membership: IProjectMembership) =>
          iriFromIModelOrIRI(membership.project) === iriFromIModelOrIRI(onboardingMemberApplication.project))

        if (!oldProjectMembershipOftheUser) {
          // the user did not already apply for the project
          onboardingMemberApplication.user = userForMemberApplication["@id"]

          resultingObject = yield call(apiClient.createEntity, EntityType.ProjectMembership, onboardingMemberApplication)

          notificationAction = addNotificationAction("message.project.memberships.saved", "success")
          successfullyCreatedNewObject = true
        } else {
          resultingObject = oldProjectMembershipOftheUser

          notificationAction = addNotificationAction(
            "message.project.memberships." +
              oldProjectMembershipOftheUser.role === onboardingMemberApplication.role
              ? "applicationAlreadyExists"
              : "otherMembershipAlreadyExists"
            , "info"
            , { autoClose: false }
          )
          successfullyCreatedNewObject = false
        }
        break

      case OnboardingType.NewProjectFollowership:
        const onboardingProjectFollowership: IProjectFollowership = yield select((s: AppState) => s.onboardingData.newProjectFollowership)

        // since there may already exist a followership between the user and the project, we must check this first
        const userForFollowership: IUser = yield call(getCurrentUser)
        const oldFollowershipOftheUser = userForFollowership.followerships?.find((followership: IProjectFollowership) =>
          iriFromIModelOrIRI(followership.project) === iriFromIModelOrIRI(onboardingProjectFollowership.project))

        if (!oldFollowershipOftheUser) {
          // the user did not already apply for the project
          onboardingProjectFollowership.user = userForFollowership["@id"]

          // transform entity to its DTO representation, if applicable
          const onboardingProjectFollowershipDTO: IProjectFollowershipWriteDTO = transformEntityToWriteDTO(EntityType.ProjectFollowership, onboardingProjectFollowership)

          resultingObject = yield call(apiClient.createEntity, EntityType.ProjectFollowership, onboardingProjectFollowershipDTO)

          notificationAction = addNotificationAction(prefixedKey("follow-project", "saved"), "success")
          successfullyCreatedNewObject = true
        } else {
          resultingObject = oldFollowershipOftheUser

          notificationAction = addNotificationAction(prefixedKey("follow-project", "alreadyExists"), "info", { autoClose: false })
          successfullyCreatedNewObject = false
        }
        break
    }

    yield put(resetOnboardingStateAction())

    if (successfullyCreatedNewObject) {
      yield put(createModelSuccessAction(entityType, resultingObject))
    }

    // refresh user object and list of own projects
    // @todo prüfen, ob die beiden Aufrufe relevant sind; ggf abgleichen mit generalSaga.refreshConnectedEntities
    yield putWait(loadCurrentUserAction())
    yield putWait(newLoadCollectionAction(EntityType.Project, null, ScopeTypes.MyProjects))

    yield put(notificationAction)

    yield put(newSingleEntityUsecaseRequestSuccessAction(entityType, usecaseKey, resultingObject))

    if (action.onSuccess) {
      yield call(action.onSuccess, onboardingType, resultingObject)
    }

    return resultingObject
  } catch (err) {

    let errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    // We need a special error handling here, since we do not have direct access to a form
    // (although it's still visible for the user on the login page).
    // We won't be using setErrors as in other sagas.

    // Therefore we firstly determine the one/single errorMessage
    if (err instanceof SubmissionError) {
      // if we got more than 1 message then use general message
      const keys = Object.keys(err.errors)
      if (keys.length === 1 && typeof err.errors[keys[0]] === "string") {
        errorMessage = err.errors[keys[0]]
      }
    }

    // We want to show a generic error message as Toast/notification.
    yield put(addNotificationAction("error:validate.general.submissionFailed", "error"))

    // Additionally, we'll call a possible onError callback with the (probably more technical) error message.
    if (action.onError) {
      yield call(action.onError, errorMessage)
    }

    yield put(newSingleEntityUsecaseRequestRunningAction(entityType, usecaseKey, errorMessage))

    return null
  }
}

// #endregion

// *************************************************************************************************
// #region onboarding state handling

interface IOnboardingStateAction extends Action {
  // NOTE should only contain "state" usecases that are not "usecases" at all b/c they are never directly triggered from user (interaction)
  type: OnboardingUsecases
}

interface IAddNewIdeaOnboardingAction extends IOnboardingStateAction {
  idea: IProject
  type: OnboardingUsecases.AddNewIdea
}

export const addNewIdeaOnboardingAction = (idea: IProject): IAddNewIdeaOnboardingAction => ({
  idea,
  type: OnboardingUsecases.AddNewIdea,
})

interface IAddNewProjectFromIdeaOnboardingAction extends IOnboardingStateAction {
  project: IProjectCreation
  type: OnboardingUsecases.AddNewProjectFromIdea
}

export const addNewProjectFromIdeaOnboardingAction = (project: IProjectCreation): IAddNewProjectFromIdeaOnboardingAction => ({
  project,
  type: OnboardingUsecases.AddNewProjectFromIdea,
})

interface IAddNewProjectMemberApplicationOnboardingAction extends IOnboardingStateAction {
  projectMembership: IProjectMembership
  type: OnboardingUsecases.AddNewProjectMemberApplication
}

export const addNewProjectMemberApplicationOnboardingAction = (memberApplication: IProjectMembership): IAddNewProjectMemberApplicationOnboardingAction => ({
  projectMembership: memberApplication,
  type: OnboardingUsecases.AddNewProjectMemberApplication,
})

interface IAddNewProjectFollowershipOnboardingAction extends IOnboardingStateAction {
  projectFollowership: IProjectFollowership
  type: OnboardingUsecases.AddNewProjectFollowership
}

export const addNewProjectFollowershipOnboardingAction = (projectFollowership: IProjectFollowership): IAddNewProjectFollowershipOnboardingAction => ({
  projectFollowership,
  type: OnboardingUsecases.AddNewProjectFollowership,
})

interface IResetOnboardingStateAction extends IOnboardingStateAction {
  type: OnboardingUsecases.ResetOnboardingData
}

const resetOnboardingStateAction = (): IResetOnboardingStateAction => ({
  type: OnboardingUsecases.ResetOnboardingData,
})

/**
 * existing/allowed Types of IModel, for which an Onboarding process exists
 * export for tests
 */
export type OnboardingModelTypes = IProject | IProjectCreation | IProjectMembership | IProjectFollowership

// export for tests
export interface IOnboardingState {
  newIdea: IProject
  newProjectFromIdea: IProjectCreation
  newProjectMemberApplication: IProjectMembership
  newProjectFollowership: IProjectFollowership
}

// export for tests
export const emptyOnboardingState: IOnboardingState = {
  newIdea: null,
  newProjectFromIdea: null,
  newProjectMemberApplication: null,
  newProjectFollowership: null,
}

export const onboardingReducer =
  (state: IOnboardingState = emptyOnboardingState, action: IOnboardingStateAction): IOnboardingState => {
    switch (action.type) {
      case OnboardingUsecases.AddNewIdea:
        const ideaAction = action as IAddNewIdeaOnboardingAction
        return {
          ...state,
          newIdea: ideaAction.idea
        }

      case OnboardingUsecases.AddNewProjectFromIdea:
        const projectAction = action as IAddNewProjectFromIdeaOnboardingAction
        return {
          ...state,
          newProjectFromIdea: projectAction.project
        }

      case OnboardingUsecases.AddNewProjectMemberApplication:
        const memberApplicationAction = action as IAddNewProjectMemberApplicationOnboardingAction
        return {
          ...state,
          newProjectMemberApplication: memberApplicationAction.projectMembership
        }

      case OnboardingUsecases.AddNewProjectFollowership:
        const followershipAction = action as IAddNewProjectFollowershipOnboardingAction
        return {
          ...state,
          newProjectFollowership: followershipAction.projectFollowership
        }

      case OnboardingUsecases.ResetOnboardingData:
        return { ...emptyOnboardingState }

      default:
        return state
    }
  }

export const selectOnboardingType = (state: AppState): OnboardingType => {
  // NOTE we're silently ignoring the case where more than 1 onboarding data is present

  if (state.onboardingData.newIdea) {
    return OnboardingType.NewIdea
  }
  if (state.onboardingData.newProjectFromIdea) {
    return OnboardingType.NewProjectFromIdea
  }
  if (state.onboardingData.newProjectMemberApplication) {
    return OnboardingType.NewProjectMemberApplication
  }
  if (state.onboardingData.newProjectFollowership) {
    return OnboardingType.NewProjectFollowership
  }

  return OnboardingType.None
}

/**
 * Test if there exists an onboarding data element of another type and returns the type,
 * otherwise null.
 *
 * @param state The AppState
 * @param excludedOnboardingType The OnboardingType that should be excluded
 * @returns the type of the other onboarding data element in the global state.onboardingData; otherwise null
 */
export const selectOtherExistingOnboardingType = (state: AppState, excludedOnboardingType: OnboardingType): OnboardingType => {
  const type = selectOnboardingType(state)
  if ([OnboardingType.None, excludedOnboardingType].includes(type)) {
    return null
  } else {
    return type
  }
}


// #endregion
