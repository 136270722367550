import { Action } from "redux"
import { call, put, takeEvery } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import apiClient from "@api/client"
import { IProject, IProjectReport, ImplementationState, ProjectProgress, ProjectState } from "@api/schema"
import { IFilterCriteria, IFormikActions, ILoadCollectionByAction, newSingleEntityUsecaseRequestRunningAction, newSingleEntityUsecaseRequestSuccessAction } from "@redux/helper/actions"
import { IFilteredCollectionState } from "@redux/helper/reducers"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { AppState } from "@redux/reducer"
import { selectCollectionUsecaseState } from "@redux/reducer/data"
import { ActionTypes, EntityType } from "@redux/reduxTypes"
import { ProjectPlanStates } from "@services/projectHelper"
import { SubmissionError } from "@services/submissionError"


/** *************************************************************************************************
 * This enum defines the usecases around the "projects marketplace".
 */
enum MarketplaceUsecases {
  /**
   * load marketplace projects
   */
  LoadMarketplaceProjects = "_usecase_load_marketplace_projects",
  /**
   * set search parameters for marketplace - stores search params in the appstate (uses a reducer)
   */
  SetMarketSearchParams = "SET_MARKET_SEARCH_PARAMS",
  /**
   * report project misuse - sends project misuse report to the API (uses a saga)
   */
  ReportProject = "REPORT_PROJECT",
}



// *************************************************************************************************
// #region load marketplace projects

/**
 * Creates an ILoadCollectionByAction to trigger the asynchroneous loading of a collection of Objects,
 * filtered by given criteria.
 * This method matches the new INewUsecaseRequestAction/entityUsecaseReducer style
 */
export const loadMarketplaceProjectsUsecaseAction =
  (marketSearchParams: IMarketSearchParams, loadAll?: boolean): ILoadCollectionByAction => {
    const criteria = marketFilterCriteriaPreparationForLoadCollection(marketSearchParams)
    return {
      criteria,
      loadAll,
      usecaseKey: MarketplaceUsecases.LoadMarketplaceProjects,
      entityType: EntityType.Project,
      type: ActionTypes.LoadCollection,
    } as ILoadCollectionByAction
  }

/**
 * Creates an IFilteredCollectionState that contains the result of the collection loading,
 * filtered by given criteria.
 * This method matches the new INewUsecaseRequestAction/entityUsecaseReducer style
 */
export const selectLoadedMarketplaceProjectsUsecaseState = (state: AppState): IFilteredCollectionState => {
  return selectCollectionUsecaseState(state, EntityType.Project, MarketplaceUsecases.LoadMarketplaceProjects)
}

/**
 * A function that builds the filter criteria for marketplace load filteredCollection action
 *
 * @returns the filter criteria
 */
const marketFilterCriteriaPreparationForLoadCollection = (searchParams: IMarketSearchParams): IFilterCriteria => {

  // taken from /redux/saga/marketplace
  // NOTE/TODO maybe make sure that action.criteria IS EMPTY?

  // calculate search criteria before sending to the backend, depending on given MarketProjectFilterType
  const progressPattern: ProjectProgress[] = []
  const implementationStatePattern: ImplementationState[] = []

  // add criteria for ideas
  if (searchParams.projectTypeFilter.includes(MarketProjectFilterType.Ideas)) {
    progressPattern.push(ProjectProgress.Idea)
    implementationStatePattern.push(ImplementationState.Planning)
  }

  // add criteria for projects in planning state
  if (searchParams.projectTypeFilter.includes(MarketProjectFilterType.ProjectsInPlanning)) {
    progressPattern.push(...ProjectPlanStates)
    if (!implementationStatePattern.includes(ImplementationState.Planning)) {
      implementationStatePattern.push(ImplementationState.Planning)
    }
  }

  // add criteria for projects in running state
  if (searchParams.projectTypeFilter.includes(MarketProjectFilterType.ProjectsRunning)) {
    implementationStatePattern.push(ImplementationState.Running)
  }
  // add criteria for projects in finished state
  if (searchParams.projectTypeFilter.includes(MarketProjectFilterType.ProjectsFinished)) {
    implementationStatePattern.push(ImplementationState.Finished)
  }

  const query: IFilterCriteria = {
    progress: progressPattern,
    state: ProjectState.Active,
    implementationState: implementationStatePattern,
  }

  if (searchParams.pattern) {
    // Workaround for https://futureprojects.atlassian.net/browse/FCP-1376
    // b/c “uppercase umlaute” like “Ü” are currently not supported by the api
    // adapted not the form itself,
    // because the user input should not be changed to avoid confusion
    query.pattern = searchParams.pattern.toLocaleLowerCase()
  }

  if (searchParams.sortBy) {
    query.order = { [searchParams.sortBy]: searchParams.sortOrder || "ASC" }
  }

  if (searchParams.categories && searchParams.categories.length) {
    query.categories = searchParams.categories
  }

  if (searchParams.sdgs && searchParams.sdgs.length) {
    query.sdgs = searchParams.sdgs
  }

  return query
}

// #endregion


// *************************************************************************************************
// #region set search parameters for marketplace

export enum IMarketSortType {
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  Name = "name",
  MemberCount = "memberCount",
}

export enum IMarketSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * Type of project states, that may be shown for filtering in the marketplace.
 * Used to filter for IProject.progress and IProject.implementationState (NOT IProject.state!).
 * Translating from FilterTypes to API filter criterias is done in the loadMarketProjectsSaga.
 */
export enum MarketProjectFilterType {
  Ideas = "ideas",
  // because only projects may be planned/running/finished the attribute name is extended by "Projects..." to clearly distinguish from ideas
  ProjectsInPlanning = "planning",
  ProjectsRunning = "running",
  ProjectsFinished = "finished"
}

export interface IMarketSearchParams {
  categories?: number[]
  pattern: string
  sdgs?: number[]
  sortBy?: IMarketSortType
  sortOrder?: IMarketSortOrder
  projectTypeFilter?: MarketProjectFilterType[]
}

/**
 * Action to trigger the (re)set of search-parameters for the Marketplace
 */
interface ISetMarketSearchParamsAction extends Action {
  params: IMarketSearchParams
  type: MarketplaceUsecases.SetMarketSearchParams
}

export const setMarketSearchParamsAction = (params: IMarketSearchParams): ISetMarketSearchParamsAction => ({
  params,
  type: MarketplaceUsecases.SetMarketSearchParams,
})

/**
 * Empty search parameters object
 */
export const initialMarketSearchParams: IMarketSearchParams = {
  categories: [],
  pattern: "",
  sdgs: [],
  sortBy: IMarketSortType.CreatedAt,
  sortOrder: IMarketSortOrder.DESC,
  projectTypeFilter: [MarketProjectFilterType.ProjectsInPlanning]
}

/**
 * Integrates changes in the marketplace search parameters
 */
export const marketPlaceSearchStateReducer =
  (state: IMarketSearchParams = initialMarketSearchParams, action: ISetMarketSearchParamsAction): IMarketSearchParams => {
    switch (action.type?.toUpperCase()) {

      case MarketplaceUsecases.SetMarketSearchParams.toUpperCase():
        const params = action.params
        return {
          ...state,
          categories: !params.projectTypeFilter.includes(MarketProjectFilterType.Ideas) ? params.categories : null,
          projectTypeFilter: params.projectTypeFilter,
          sdgs: !params.projectTypeFilter.includes(MarketProjectFilterType.Ideas) ? params.sdgs : null,
          pattern: params.pattern,
          sortBy: params.sortBy,
          sortOrder: params.sortOrder,
        }

      default:
        return state
    }
  }

// #endregion


// *************************************************************************************************
// #region report project misuse

/**
 * Action to trigger a report to report the misuse of a Project
 */
interface IReportProjectAction extends Action {
  actions: IFormikActions
  project: IProject
  report: IProjectReport
  type: MarketplaceUsecases.ReportProject
}

export const reportProjectAction = (project: IProject, report: IProjectReport, actions: IFormikActions): IReportProjectAction => ({
  actions,
  project,
  report,
  type: MarketplaceUsecases.ReportProject,
})

/**
 * Saga watcher method that is registered in redux/sagas/index rootSaga
 */
export function* marketplaceWatcherSaga(): any {
  // yield takeLatest(MarketplaceActionTypes.LoadMarketProjects, withCallback(loadMarketProjectsSaga))
  yield takeEvery(MarketplaceUsecases.ReportProject, withCallback(reportProjectSaga))
}

/**
 * saga to trigger a misuse-report by a user
 */
function* reportProjectSaga(action: IReportProjectAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}

  // special (non-default) sagas for special (non-default) actions use their special usecaseKey (identical to action.type)
  const usecaseKey = action.type // MarketplaceUsecases.ReportProject // @TODO fixme: usecaseKey may be refactored into action, see loadModelAction etc

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Project, usecaseKey))

    yield call(apiClient.reportProject, action.project, action.report)

    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.Project, usecaseKey, action.project))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }
    if (onSuccess) {
      yield call(onSuccess)
    }

    return true
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Project, usecaseKey, errorMessage))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return false
  }
}

// #endregion
