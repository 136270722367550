import { NextRouter } from "next/router"

import { stringToInt } from "@services/util"

import { useWindowLocation } from "./windowHooks"

/**
 * To avoid typos and to allow changing the URLs later all links should use this enum
 * to organize existing pages.
 */
export enum Routes {
  // #region basic public pages
  Home = "/",
  About = "/about",
  Contact = "/contact",
  DataProtection = "/data-protection",
  Netiquette = "/netiquette",
  TermsOfUse = "/terms-of-use",
  Imprint = "/imprint",
  Pubtools = "/tools/pubtools",
  ProcessOverview = "/process",
  Categories = "/process/categories",
  ChallengePage = "/challenges/[slug]",
  // #endregion

  // #region Tools
  FAQ = "/tools/faq",
  // #endregion

  // #region user handling
  ConfirmAccount = "/user/confirm-account/[id]/[token]",
  ConfirmEmailChange = "/user/confirm-email/[id]/[token]",
  ConfirmPasswordReset = "/user/reset-password/[id]/[token]",
  Feedback = "/user/feedback",
  ForgotPassword = "/user/forgot-password",
  ImportProject = "/user/import-project",
  Login = "/user/login",
  Registration = "/user/register",
  UserDashboard = "/user/dashboard",
  MySupportOffers = "/user/supportoffers",
  // #endregion

  // #region public project data/pages
  Marketplace = "/projects",
  PartnerMarket = "/partner-market",
  ProjectPage = "/projects/[slug]", // also works for ID
  CreateIdea = "/ideas/create",
  CreateProject = "/projects/create?inspiration=[id]",
  ProjectApplication = "/projects/[slug]/apply",
  // #endregion

  // #region project pages for project team members
  MyProjects = "/user/projects",
  ProjectAttachments = "/projects/[slug]/challenge-proposal/attachments",
  ProjectProfile = "/projects/[slug]/profile",
  ProjectProfileEdit = "/projects/[slug]/profile/edit",
  ProjectSelectChallenge = "/projects/[slug]/challenge-proposal/select-challenge",
  ProjectConcretization = "/projects/[slug]/challenge-proposal/concretizations",
  ProjectConcretizationEdit = "/projects/[slug]/challenge-proposal/concretizations/edit",
  ProjectFeedbackDashboard = "/projects/[slug]/feedback",
  ProjectFeedbackPage = "/projects/[slug]/feedback/feedback",
  ProjectMap = "/projects/[slug]/profile/map",
  ProjectMembers = "/projects/[slug]/team/members",
  ProjectNetworkDashboard = "/projects/[slug]/network",
  ProjectNetworkCreateSupportRequest = "/projects/[slug]/network/supportrequests/create",
  ProjectNetworkSupportRequests = "/projects/[slug]/network/supportrequests",
  ProjectNetworkSupportOffersOfOneSupportRequest = "/projects/[slug]/network/supportrequests/[id]/supportoffers",
  ProjectPartners = "/projects/[slug]/plan/partners",
  ProjectOwnContributions = "/projects/[slug]/plan/own-contributions",
  ProjectPlan = "/projects/[slug]/plan",
  ProjectPlanDescription = "/projects/[slug]/plan/description",
  ProjectPlanTargetgroupsImpact = "/projects/[slug]/plan/targetgroups-impact",
  ProjectPlanTasks = "/projects/[slug]/plan/tasks",
  ProjectPlanWorkPackages = "/projects/[slug]/plan/work-packages",
  ProjectPlanTimetable = "/projects/[slug]/plan/timetable",
  ProjectPlanResourceRequirements = "/projects/[slug]/plan/resource-requirements",
  ProjectPlanResourceCostCategories = "/projects/[slug]/plan/cost-categories?proposal=[proposal]",
  ProjectPlanFinances = "/projects/[slug]/plan/finances",
  ProjectProposals = "/projects/[slug]/challenge-proposal",
  ProjectStandingData = "/projects/[slug]/challenge-proposal/standing-data",
  ProjectStandingDataEdit = "/projects/[slug]/challenge-proposal/standing-data/edit",
  ProjectProposalChallengeModuleInActive = "/projects/[slug]/challenge-proposal/module-inactive-proposal-details",
  ProjectProposalInActive = "/projects/[slug]/challenge-proposal/inactive-proposal-details?proposal=[proposal]",
  ProjectProposalPostSubmission = "/projects/[slug]/challenge-proposal/post-submission",
  ProjectProposalSubmission = "/projects/[slug]/challenge-proposal/submission",
  TeamMeeting = "/projects/[slug]/team/teammeeting",
  TeamUpload = "/projects/[slug]/team/team-uploads",
  // #endregion

  // #region basic administrative pages for process manager / admin
  AdminDashboard = "/management",
  ProcessCreate = "/process/create",
  ProcessEdit = "/process/edit",
  ProcessCategories = "/management/process/[id]/categories",
  ProcessCategoriesAdd = "/management/process/[id]/categories/add",
  ProcessCategoriesEdit = "/management/process/[id]/categories/[category]/edit",

  AdminProjectSearch = "/management/projects",
  AdminProjectDetails = "/management/projects/[id]",
  AdminProjectProfile = "/management/projects/[id]/profile",

  AdminUserSearch = "/management/users",
  AdminUserDetails = "/management/users/[id]",

  AdminSystemOverview = "/management/system",
  AdminSystemIcons = "/management/system/icons",
  // #endregion

  // #region challenge/fund workflow
  // to differentiate the overview over challenges/funds we use a type-parameter
  AdminChallengeOverview = "/management/challenges?type=[type]",
  // the creation of a challenge may result in a BasicChallenge or a Fund. To diffenciate we use a type-parameter
  AdminChallengeCreate = "/management/challenges/create?type=[type]",
  AdminChallengeEdit = "/management/challenges/[id]/details/edit",

  AdminChallengeDetails = "/management/challenges/[id]/details",
  AdminChallengeDetailsEdit = "/management/challenges/[id]/details/edit",
  AdminChallengeConcretization = "/management/challenges/[id]/concretization",
  AdminChallengeConcretizationCreate = "/management/challenges/[id]/concretization/add",
  AdminChallengeConcretizationEdit = "/management/challenges/[id]/concretization/edit",
  AdminChallengeProcedureAndTimeline = "/management/challenges/[id]/procedure-timeline",
  AdminChallengeProcedureAndTimelineEdit = "/management/challenges/[id]/procedure-timeline/edit",
  AdminChallengeViewProposals = "/management/challenges/[id]/view-proposals",
  AdminChallengeSelectProposals = "/management/challenges/[id]/select-proposals",
  AdminChallengeGrant = "/management/challenges/[id]/grant",
  AdminChallengeGrantEdit = "/management/challenges/[id]/grant/edit",

  // transitioning the challege from one state to another are handled by one transition-page
  ChallengeTransitionPage = "/management/challenges/[id]/transition?transition=[transition]",
  // #endregion challenges/fund workflow

  // #region feedback for management
  AdminFeedbackInvitationTimeline = "/management/feedback/invitation/[id]/timeline",
  AdminFeedbackInvitationActivate = "/management/feedback/invitation/[id]/activate",
  AdminFeedbackInvitationCreate = "/management/feedback/invitation/create",
  AdminFeedbackInvitationEdit = "/management/feedback/invitation/[id]/edit",
  AdminFeedbackInvitationResults = "/management/feedback/invitation/[id]/results",
  AdminFeedbackInvitations = "/management/feedback/invitation",
  AdminFeedbackInvitationView = "/management/feedback/invitation/[id]",
  // #endregion

  // download-page for files/pdf
  DownloadTriggerPage = "/download/[type]/[id]",
}

/**
 * Routes to the backend, especially to download files
 */
export enum BackendRoutes {
  ProposalAttachmentDownload = "/proposal_attachments/[id]/download",
  ProposalPdfDownload = "/proposals/[id]/proposal-pdf-download",
  ProposalTeamPdfDownload = "/proposals/[id]/team-pdf-download",
  ProjectPdfDownload = "/projects/[id]/pdf-download",
  TeamUploadDownload = "/team_uploads/[id]/download",
}

// #region helper functions for routes

/**
 * Creates a valid URL from a given route and given parameters, attached to this route
 *
 * @param route a valid route from pre-defined possible Routes
 * @param params a set of parameters
 * @param redirectBack a link, where the user should be sended AFTER finishing the job on the route-URL (must be implemented when needed on the route-url-page!)
 * @returns a valid URL set up from route and params
 */
export const routeWithParams = (
  route: Routes | BackendRoutes,
  params: { [param: string]: string | number },
  redirectBack?: string
): string => {
  let result: string = route
  // replace all params-keys, exististing in the pattern "[key]" in the route
  // by the value of the param-key, e.g.
  // params: {non: "sense"}
  // will replace all "[non]" elements in the route string by "sense"
  Object.keys(params).forEach((name) => {
    result = result.replace(`[${name}]`, encodeURIComponent(params[name].toString()))
  })


  if (redirectBack) {
    const redirectBackParam = "redirectBack=" + encodeURIComponent(redirectBack)
    // check, if the original route already contains a "?"
    // to avoid reacting on a "?" that is injected by a param content
    // we assume, the given route is valid
    //
    // new URL(...).searchParams.append does not work, because it forces to use a URL but the Routes
    // are paths, not URLs
    if (route.indexOf("?") === -1) {
      // attach "?" to attach redirectBack param, if there is no "?" already
      result = result + "?" + redirectBackParam
    } else {
      result = result + "&" + redirectBackParam
    }
  }

  return result
}

/**
 * Returns a parameter from the current URL if they are string-able.
 *
 * @param router a given router
 * @param param the expected parameter of the url
 * @returns the value of the parameter, if it is a string, otherwise null
 */
export const getStringFromQuery = (router: NextRouter, param: string): string => {
  const value = router.query[param]
  return typeof value === "string" ? value : null
}

/**
 * Returns a parameter from the current URL as an integer.
 *
 * @param router a given router
 * @param param the expected parameter of the url
 * @returns the value of the parameter if it is convertable to an integer, otherwise null
 */
export const getIntFromQuery = (router: NextRouter, param: string): number =>
  stringToInt(getStringFromQuery(router, param))

/**
 * Removes all query parameters from the current URL.
 * currently unused
 *
 * @param router a given router
 */
export const removeAllQueryParams = (router: NextRouter): void => {
  void router.replace(
    router.pathname,
    router.pathname,
    { shallow: true }
  )
}

/**
 * Returns a regex pattern of the given route to be used to test if links match the route.
 * Usage:
 * routeRegexPattern(Routes.AdminUserDetails).test("/management/users/8")
 *
 * @param route route to be returned as regex pattern
 * @returns RegExp that may be used to test a string for matching
 *
 * NOTE: that does work for most of the routes but not for all yet: @see tests
 */
export const routeRegexPattern = (route: Routes): RegExp =>
  // replacing all [...] by regex .* to test afterward if the resulting regex matches a given link string
  new RegExp("^" + route.replaceAll(/\[\w+\]/g, "[^/]+") + "$")


/**
 * @param link string that should match the given route
 * @param route route which pattern should the link match
 * @returns returns true, if a given link matches a given route pattern
 */
export const linkMatchesRoute = (link: string, route: Routes): boolean =>
  // replacing all [...] by regex .* to test afterward if the resulting regex matches the href found on a link element
  routeRegexPattern(route)
    .test(link)

// #endregion helper functions

// #region hooks

/**
 * Returns a login link with an encoded redirectBack-Parameter or, if no redirectBack param is given,
 * the URL of the current page as redirectBack link
 *
 * Provided as hook b/c window must be initialized when page is loaded
 *
 * @param redirectBack a URL where the user should be sent after login
 * @returns a valid login link with redirectBack param
 */
export const useLoginRouteWithRedirectBack = (redirectBack?: string): string => {
  const location = useWindowLocation()
  const locationUrl = location ? location.pathname + location.search + location.hash : ""

  if (redirectBack) {
    return Routes.Login + "?redirectBack=" + encodeURIComponent(redirectBack)
  } else {
    return Routes.Login + (locationUrl ? "?redirectBack=" + encodeURIComponent(locationUrl) : "")
  }
}

// endregion hooks